// Donate page (/donate)

#donate {
  max-width: 1080px;
  height: 1200px;
  box-sizing: border-box;
}

.inline {
    max-width: 150px;
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 15;
    outline: 0;
    border: 0;
    text-align: right;
}
